/**
 * WhiteVega Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface JWTContent { 
    iat: number;
    exp: number;
    roles: Array<JWTContent.RolesEnum>;
    username: string;
    id: number;
    company_id: number;
}
export namespace JWTContent {
    export type RolesEnum = 'ROLE_ADMIN' | 'ROLE_USER';
    export const RolesEnum = {
        Admin: 'ROLE_ADMIN' as RolesEnum,
        User: 'ROLE_USER' as RolesEnum
    };
}


