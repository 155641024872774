import {Component} from '@angular/core';
import {AuthService} from "./services/auth.service";
import {MenuController} from "@ionic/angular";
import {NavigationEnd, Router} from "@angular/router";
import {filter, Subscription} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  protected pages = [
    {
      title: 'Anillar llave',
      url: '/tabs/ring-keys'
    },
    {
      title: 'Llaves disponibles',
      url: '/tabs/available-keys'
    },
    {
      title: 'Mis llaves',
      url: '/tabs/my-keys'
    },
    {
      title: 'Mi perfil',
      url: '/tabs/user'
    },
  ];
  private subscriber: Subscription | undefined;
  constructor(private auth: AuthService, private menuCtrl: MenuController, private router: Router) {
    this.subscriber = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      this.menuCtrl.close().then();
    });
  }

  async logout () {
    await this.menuCtrl.close();
    await this.auth.logout();
  }
}
