<ion-app>
  <ion-menu content-id="content" type="overlay">
    <ion-header>
      <ion-toolbar>
        <ion-title>Menú</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item *ngFor="let p of pages" [routerLink]="p.url" routerDirection="root">
          {{p.title}}
        </ion-item>
        <ion-item (click)="logout()">
          Salir
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="content"></ion-router-outlet>
</ion-app>
