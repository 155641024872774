/**
 * WhiteVega Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface UsersGET { 
    readonly id?: number;
    email: string;
    name?: string | null;
    surname?: string | null;
    readonly identity_type?: string | null;
    readonly identity_id?: string | null;
    readonly phone_number?: string | null;
    department?: string | null;
    readonly address_city?: string | null;
    readonly address_street?: string | null;
    readonly address_street_number?: number | null;
    readonly address_street_floor?: string | null;
    readonly address_zipcode?: string | null;
    company?: string | null;
}

