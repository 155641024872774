/**
 * WhiteVega Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface Key { 
    readonly id?: number;
    readonly photo_data?: string | null;
    readonly photo_type?: string | null;
    readonly photo_h?: number | null;
    readonly photo_w?: number;
    readonly is_master?: boolean;
    keychain?: string | null;
    photoData?: any | null;
    photoType?: string | null;
    photoH?: number | null;
    photoW?: number;
    isMaster?: boolean;
}

