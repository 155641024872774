import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { WVConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { CompaniesService } from './api/companies.service';
import { EmailService } from './api/email.service';
import { KeychainService } from './api/keychain.service';
import { KeychainAccessService } from './api/keychainAccess.service';
import { LoginService } from './api/login.service';
import { MQTTService } from './api/mQTT.service';
import { NotificationsService } from './api/notifications.service';
import { UsersService } from './api/users.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class WVApiModule {
    public static forRoot(configurationFactory: () => WVConfiguration): ModuleWithProviders<WVApiModule> {
        return {
            ngModule: WVApiModule,
            providers: [ { provide: WVConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: WVApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('WVApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
