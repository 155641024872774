import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {isLoggedIn, isLoggedOut} from "./services/jwt.control.service";
import {LockerPageModule} from "./locker/locker.module";

const routes: Routes = [
  {
    path: 'home-old',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [isLoggedOut]
  },
  {
    path: 'company',
    loadChildren: () => import('./company/company.module').then( m => m.CompanyPageModule),
    canActivate: [isLoggedIn],
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/user/user.module').then(m => m.UserPageModule),
  },
  {
    path: 'locker/:id',
    loadChildren: () => import('./locker/locker.module').then(m => m.LockerPageModule),
    canActivate: [isLoggedIn],
  },
  {
    path: 'locker-select',
    loadChildren: () => import('./locker-select/locker-select.module').then( m => m.LockerSelectPageModule),
    canActivate: [isLoggedIn],
  },
  {
    path: 'company-manage/:id',
    loadChildren: () => import('./company-manage/company-manage.module').then( m => m.CompanyManagePageModule),
    canActivate: [isLoggedIn],
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'available-keys',
    loadChildren: () => import('./pages/available-keys/available-keys.module').then(m => m.AvailableKeysPageModule)
  },
  {
    path: 'my-keys',
    loadChildren: () => import('./pages/my-keys/my-keys.module').then(m => m.MyKeysPageModule)
  },
  {
    path: 'ring-keys',
    loadChildren: () => import('./pages/ring-keys/ring-keys.module').then( m => m.RingKeysPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
})
export class AppRoutingModule { }
