/**
 * WhiteVega Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export interface NotificationsGET { 
    title?: string;
    content?: string;
    type?: string;
    date?: string;
    link?: string | null;
    readonly is_read?: boolean;
    user?: string | null;
}

