import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy, RouterModule} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {WVApiModule, WVConfiguration} from './external/wv';
import {IonicStorageModule} from "@ionic/storage-angular";
import {authConfig, JwtControlService} from "./services/jwt.control.service";
import {HttpClientModule} from "@angular/common/http";
import {AuthService} from "./services/auth.service";

@NgModule({
  declarations: [AppComponent],
  imports: [
    IonicModule.forRoot(), IonicStorageModule.forRoot(),
    BrowserModule, AppRoutingModule, HttpClientModule, RouterModule,
    WVApiModule,
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: WVConfiguration, useFactory: authConfig, deps: [JwtControlService]},
    JwtControlService,
    AuthService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
