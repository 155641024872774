import {Injectable} from '@angular/core';
import {concatMap, from, map, Observable, tap} from "rxjs";
import {LoginCheckPost200Response, LoginService} from "../external/wv";
import {JwtControlService} from "./jwt.control.service";
import {Router} from "@angular/router";

// TODO: investigate on page de-initilization / guards
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private router: Router, private api: LoginService, private _jwt: JwtControlService) {
    this.jwt.expiration.subscribe(() => {
      this.refreshToken().subscribe();
    })
  }

  get jwt() {
    return this._jwt;
  }

  public login(credentials: {username: string, password: string}): Observable<boolean> {
    return this.api.loginCheckPost({email: credentials.username, password: credentials.password})
      .pipe(
        concatMap((resp: LoginCheckPost200Response) => {
          return from(
            this.jwt.store(resp.token, resp.refresh_token)
              .then(() => this.router.navigate(['/home']))
          );
        }),
        tap(() => {
          this.jwt.expiration.subscribe(() => {
            this.refreshToken().subscribe();
          })
        }),
      );
  }

  public refreshToken(): Observable<boolean> {
    return this.api.loginRefreshPost({refresh_token: this.jwt.refreshToken!})
      .pipe(
        tap((resp) => {
          from(this.jwt.store(resp.token, resp.refresh_token));
        }),
        tap((_) => {
          this.jwt.expiration.subscribe(() => {
            this.refreshToken().subscribe();
          })
        }),
        map((_) => true),
      )
  }

  public async logout(): Promise<any> {
    await this._jwt.remove();
    return this.router.navigate(['/login']);
  }
}
