import {inject, Injectable} from '@angular/core';
import {Storage} from "@ionic/storage-angular";
import * as jwt from 'jsonwebtoken';
import {JWTContent, WVConfiguration} from "../external/wv";
import {CanActivateFn, Router} from "@angular/router";
import {delay, NEVER, Observable, of} from "rxjs";

const ACCESS_TOKEN_KEY = 'access-token';
const REFRESH_TOKEN_KEY = 'refresh-token';

export type Role = 'ROLE_ADMIN' | 'ROLE_AUTH';
export type WVJwt = jwt.Jwt & JWTContent &
  {
    iat: number,
    exp: number,
    roles: Role[],
    username: string,
    raw: string,
  };

@Injectable({
  providedIn: 'root'
})
export class JwtControlService {
  get refreshToken(): string | null {
    return this._refreshToken;
  }
  get expiration(): Observable<any> {
    return this._expiration;
  }
  get accessToken(): WVJwt | null {
    return this._accessToken;
  }

  private _accessToken: WVJwt | null = null;
  private _refreshToken: string | null = null;
  private _expiration: Observable<any> = NEVER;

  constructor(private storage: Storage) {
    this.storage.create()
      .then(s => { this.storage = s; });
  }

  public async load(): Promise<[jwt.Jwt, string] | null>  {
    const tok = await this.storage.get(ACCESS_TOKEN_KEY);
    const ref = await this.storage.get(REFRESH_TOKEN_KEY);
    if (tok !== null && ref !== null) {
      this._accessToken = (<WVJwt>jwt.decode(tok));
      this._accessToken.raw = tok;
      this._refreshToken = ref;
      this._expiration = of({}).pipe(
        delay(normalizeExpDate(this._accessToken.exp)),
      )
      if (this.accessToken !== null)
        return [this.accessToken, ref];
    }

    return null;
  };

  public async store(tok: string, ref: string): Promise<any> {
    const maybeTok = jwt.decode(tok);

    if (<WVJwt | null>maybeTok) {
      this._accessToken = <WVJwt>maybeTok;
      this._accessToken.raw = tok;
      this._refreshToken = ref;
      // const renew = new Date(this._accessToken.exp));
      this._expiration = of({}).pipe(
        delay(normalizeExpDate(this._accessToken.exp)),
      )
      return Promise.all([
        this.storage.set(ACCESS_TOKEN_KEY, tok),
        this.storage.set(REFRESH_TOKEN_KEY, ref),
      ])
    }

    return Promise.reject(<string>maybeTok)
  }

  public async remove() {
    this._accessToken = null;
    return Promise.all([
      this.storage.remove(ACCESS_TOKEN_KEY),
      this.storage.remove(REFRESH_TOKEN_KEY),
      ]);
  }
}

const normalizeExpDate = (exp: number) => {
  const fullyear = 365*24*3600*1000;
  const now = (new Date()).getTime()
  if (exp > now - fullyear && exp < now + fullyear) {
    return new Date(exp);
  } else if (exp*1000 > now - fullyear && exp < now + fullyear) {
    return new Date(exp*1000);
  } else {
    return new Date(now + 3600*1000);
  }
}

export const authConfig = (auth: JwtControlService): WVConfiguration => {
  return new WVConfiguration({
    basePath: 'https://api.ihookit.com',
    credentials:
      {JWT: () =>
          auth?.accessToken?.raw
            ? ('Bearer ' + auth.accessToken.raw)
            : undefined
      },
  });
}

const withJWT: ((logged: 'ensureLoggedIn' | 'ensureNotLoggedIn') => CanActivateFn) =
  logged =>
    async (route, state) => {
      const router = inject(Router)
      const tok = await inject(JwtControlService).load();
      switch (logged) {
        case 'ensureLoggedIn':
          if (tok === null)
            return router.parseUrl('/login');
          return true;
        case 'ensureNotLoggedIn':
          if (tok !== null)
            return router.parseUrl('/home');
          return true;
      }
    }
export const isLoggedIn = withJWT('ensureLoggedIn');
export const isLoggedOut = withJWT('ensureNotLoggedIn');
